import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Price } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "price"
    }}>{`Price`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Price from '@mfrm/mfcl/Price'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`The Price component renders and formats the pricing to USD. The optional props allow for further customization on how
the Prices render and look`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Price} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <p>{`The Price component requires the Price prop to render`}</p>
    <Playground __position={1} __code={'<Price price={[3500]} />\n<Price price={[3500, 5000]} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Price,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Price price={[3500]} mdxType="Price" />
  <Price price={[3500, 5000]} mdxType="Price" />
    </Playground>
    <h2 {...{
      "id": "alignment"
    }}>{`Alignment`}</h2>
    <p>{`The Price component can be aligned horizontally`}</p>
    <Playground __position={2} __code={'<Price price={[3500]} />\n<Price price={[3500]} center />\n<Price price={[3500]} right />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Price,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Price price={[3500]} mdxType="Price" />
  <Price price={[3500]} center mdxType="Price" />
  <Price price={[3500]} right mdxType="Price" />
    </Playground>
    <h2 {...{
      "id": "text"
    }}>{`Text`}</h2>
    <p>{`The Price component can have on either side of the price, with or without a divider`}</p>
    <Playground __position={3} __code={'<Price price={[3500]} text=\"Queen\" />\n<Price price={[3500]} text=\"Queen\" divider />\n<Price price={[3500]} rightText=\"/mo\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Price,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Price price={[3500]} text="Queen" mdxType="Price" />
  <Price price={[3500]} text="Queen" divider mdxType="Price" />
  <Price price={[3500]} rightText="/mo" mdxType="Price" />
    </Playground>
    <h2 {...{
      "id": "discounts"
    }}>{`Discounts`}</h2>
    <p>{`The Price component can be provided another array of prices indicating original pricing before a discount along with the percentage if desired`}</p>
    <Playground __position={4} __code={'<Price price={[1400, 2000]} originalPrice={[3500, 5000]} discountPercentage />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Price,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Price price={[1400, 2000]} originalPrice={[3500, 5000]} discountPercentage mdxType="Price" />
    </Playground>
    <h2 {...{
      "id": "precision"
    }}>{`Precision`}</h2>
    <p>{`The Price component can show decimals which can be aligned vertically`}</p>
    <Playground __position={5} __code={'<Price originalPrice={[5500]} price={[2400, 3000]} decimals />\n<Price originalPrice={[6500]} price={[1400, 2000]} decimalsTop />\n<Price originalPrice={[7500, 5000]} price={[2000]} decimalsBottom />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Price,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Price originalPrice={[5500]} price={[2400, 3000]} decimals mdxType="Price" />
  <Price originalPrice={[6500]} price={[1400, 2000]} decimalsTop mdxType="Price" />
  <Price originalPrice={[7500, 5000]} price={[2000]} decimalsBottom mdxType="Price" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      